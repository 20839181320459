<template>
    <div class="col-md-12 col-lg-11 col-xl-10 mx-auto pb-5 pb-lg-3 pt-2 ">
        <form @submit.prevent="save($event)">
            <div
                    :class="{
          'border-danger': !isValidated,
          'border-success': isValidated
        }"
                    class="card shadow"
            >
                <div
                        :class="{
            'bg-danger text-white': !isValidated,
            'bg-success': isValidated
          }"
                        class="card-header"
                >
                    <h2 v-if="hasId">
                        Edit {{ information_card.first }}
                        {{ information_card.last }} information for {{ schoolYear(year) }}
                        <template v-if="hasProgram">
                            in <span v-html="program.name"/>
                        </template>
                    </h2>
                    <h2 v-else>
                        Add Student and Information Card for {{ schoolYear(year) }}
                        <template v-if="hasProgram">
                            in <span v-html="program.name"/>
                        </template>
                    </h2>
                    <p class="small" v-if="!isValidated">
                        {{ countToValidate }} of {{ totalToValidate }} required fields to
                        complete
                    </p>
                </div>

                <div class="list-group list-group-flush">
                    <!-- start of optional program year picker -->
                    <div v-if="canEditProgramYear" class="list-group-item">
                        <h5>Select Program and Year</h5>
                        <div class="row">
                            <div
                                    v-wait:visible="'programs.load'"
                                    class="alert alert-warning col-md-6"
                            >
                                <cs-loading>
                                    Loading programs
                                </cs-loading>
                            </div>
                            <cs-form-group
                                    v-wait:hidden="'programs.load'"
                                    :label="'Program (optional)'"
                                    class="col-md-6"
                                    input_id="program_slug"
                                    help="This will give an option to start signing up for the program when you complete this information card"
                            >
                                <template v-slot:input>
                                    <multiselect
                                            :allowEmpty="false"
                                            :multiple="false"
                                            :options="programs"
                                            label="name"
                                            placeholder="Type to search"
                                            track-by="slug"
                                            v-model="selectedProgram"
                                    >
                    <span slot="noResult"
                    >Oops! No elements found. Consider changing the search
                      query.</span
                    >
                                    </multiselect>
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :label="'Year for Information Card'"
                                    class="col-md-6"
                                    input_id="year"
                            >
                                <template v-slot:input>
                                    <multiselect
                                            :allowEmpty="false"
                                            :multiple="false"
                                            :options="years"
                                            label="value"
                                            placeholder="Type to search"
                                            track-by="value"
                                            v-model="selectedYear"
                                    >
                    <span slot="noResult"
                    >Oops! No elements found. Consider changing the search
                      query.</span
                    >
                                    </multiselect>
                                </template>
                            </cs-form-group>
                        </div>
                    </div>
                    <!-- end of optional program year picker -->
                    <!-- start of student base -->
                    <div id="student-info"
                         :class="{
              'list-group-item-danger': !studentInfoValidated
            }"
                         class="list-group-item"
                    >
                        <div class="row">
                            <cs-form-group
                                    :validator="$v.information_card.student_first"
                                    class="col-md-6"
                                    input_id="student_first"
                                    label="First Name"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card.student_first.$invalid,
                      'is-invalid': $v.information_card.student_first.$error
                    }"
                                            class="form-control"
                                            id="student_first"
                                            type="text"
                                            v-model.trim.lazy="$v.information_card.student_first.$model"
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.student_first.minLength"
                                        >
                                            Must have at least
                                            {{
                                            $v.information_card.student_first.$params.minLength.min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.student_first.maxLength"
                                        >
                                            Must have less then
                                            {{
                                            $v.information_card.student_first.$params.maxLength.max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :validator="$v.information_card.student_last"
                                    class="col-md-6"
                                    input_id="student_last"
                                    label="Last Name"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card.student_last.$invalid,
                      'is-invalid': $v.information_card.student_last.$error
                    }"
                                            class="form-control"
                                            id="student_last"
                                            type="text"
                                            v-model.trim.lazy="$v.information_card.student_last.$model"
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.student_last.minLength"
                                        >
                                            Must have at least
                                            {{
                                            $v.information_card.student_last.$params.minLength.min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.student_last.maxLength"
                                        >
                                            Must have less then
                                            {{
                                            $v.information_card.student_last.$params.maxLength.max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                        </div>
                        <div class="row">
                            <cs-form-group
                                    :validator="$v.information_card.gender"
                                    class="col-md-4"
                                    input_id="gender"
                                    label="Gender"
                            >
                                <template v-slot:input>
                                    <br/>
                                    <div class="form-check form-check-inline">
                                        <input
                                                class="form-check-input"
                                                id="gender_male"
                                                name="gender"
                                                type="radio"
                                                v-model="$v.information_card.gender.$model"
                                                value="male"
                                        />
                                        <label class="form-check-label" for="gender_male"
                                        >Male</label
                                        >
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                                class="form-check-input"
                                                id="gender_female"
                                                name="gender"
                                                type="radio"
                                                v-model="$v.information_card.gender.$model"
                                                value="female"
                                        />
                                        <label class="form-check-label" for="gender_female"
                                        >Female</label
                                        >
                                    </div>
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.gender.$invalid"
                                        >
                                            Please select a gender for your student
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :validator="$v.information_card.enrollment_type"
                                    class="col-md-4"
                                    input_id="gender"
                                    label="Enrollment Type"
                            >
                                <template v-slot:input>
                                    <br/>
                                    <div
                                            :key="key"
                                            class="form-check form-check-inline"
                                            v-for="(enrollmentType, key) in enrollmentTypes"
                                    >
                                        <input
                                                :id="'enrollment_type_' + key"
                                                :key="'input' + key"
                                                :value="key"
                                                class="form-check-input"
                                                name="enrollment_type"
                                                type="radio"
                                                v-model="$v.information_card.enrollment_type.$model"
                                        />
                                        <label
                                                :for="'enrollment_type_' + key"
                                                :key="'label' + key"
                                                class="form-check-label"
                                        >{{ enrollmentType }}</label
                                        >
                                    </div>
                                </template>
                                <template v-slot:help>
                                    Please select the enrollment type you
                                    <strong>expect</strong> your student to be in
                                    {{ schoolYear(year) }}
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.enrollment_type.$invalid"
                                        >
                                            Please select the enrollment type
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                            <span class="col-md-4">
                <cs-form-group
                        :validator="$v.information_card.birthdayAsDate"
                        input_id="birthday"
                        label="Date of birth"
                >
                  <template v-slot:input>
                    <input
                            :class="{
                        'is-valid': !$v.information_card.birthdayAsDate
                          .$invalid,
                        'is-invalid': $v.information_card.birthdayAsDate.$error
                      }"
                            type="text"
                            class="form-control"
                            id="date_of_birth"
                            v-facade="'##/##/#####'"
                            placeholder="mm/dd/yyyy"
                            v-model="birthday"
                    />
                  </template>
                  <template v-slot:help
                  >
                    <transition mode="out-in">
                      <div v-if="birthdayOutOfRange"
                          class="alert alert-warning">
                        Date of birth must be between
                        {{ dateString(startBirthday, 'P') }} and
                        {{ dateString(endBirthday, 'P') }} <span v-if="hasProgram">for <strong>{{
                          program.name
                        }}</strong></span>
                      </div>
                      <div v-else>
                        Students <span v-if="hasProgram">in <strong>{{ program.name }}</strong></span> should be between
                    {{ minAge }} and
                    {{ maxAge }} years old on  {{ dateString(cutoffDate, 'P') }}
                      </div>
                    </transition>
                  </template>
                  <template v-slot:invalid-feedback>
                    <transition mode="out-in">
                      <div class="warning">
                        Date of birth must be between
                        {{ dateString(startBirthday, 'P') }} and
                        {{ dateString(endBirthday, 'P') }} <span v-if="hasProgram">for <strong>{{
                          program.name
                          }}</strong></span>
                      </div>
                    </transition>
                  </template>
                </cs-form-group>
              </span>
                        </div>
                        <div class="row">
                            <cs-form-group
                                    :label="'Grade for ' + year + '-' + (year + 1)"
                                    :validator="$v.information_card.grade"
                                    class="col-md-4"
                                    input_id="grade"
                            >
                                <template v-slot:input>
                                    <multiselect
                                            :allowEmpty="false"
                                            :group-select="false"
                                            :multiple="false"
                                            :options="grades"
                                            deselectLabel=""
                                            group-label="level"
                                            group-values="gradeGroup"
                                            label="name"
                                            placeholder="Type to search"
                                            track-by="value"
                                            v-model="grade"
                                    >
                    <span slot="noResult"
                    >Oops! No elements found. Consider changing the search
                      query.</span
                    >
                                    </multiselect>
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.grade.$invalid"
                                        >
                                            Please select a grade for your student
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :label="'School for ' + year + '-' + (year + 1)"
                                    :validator="$v.information_card.school"
                                    class="col-md-4"
                                    input_id="school"
                            >
                                <template v-slot:input>
                                    <multiselect
                                            :allowEmpty="false"
                                            :group-select="false"
                                            :multiple="false"
                                            :options="schools"
                                            deselectLabel=""
                                            group-label="level"
                                            group-values="schoolGroup"
                                            label="name"
                                            placeholder="Type to search"
                                            track-by="value"
                                            v-model="school"
                                    >
                    <span slot="noResult"
                    >Oops! No elements found. Consider changing the search
                      query.</span
                    >
                                    </multiselect>
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.school.$invalid"
                                        >
                                            Please select a school for your student
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>

                            <cs-form-group
                                    :validator="$v.information_card.teacher_id"
                                    class="col-md-4"
                                    input_id="teacher"
                                    label="Teacher"
                            >
                                <template v-slot:input>
                                    <multiselect
                                            :multiple="false"
                                            :options="teachers"
                                            label="summary"
                                            placeholder="Type to search"
                                            track-by="id"
                                            v-model="teacher"
                                    >
                    <span slot="noResult"
                    >Oops! No elements found. Consider changing the search
                      query.</span
                    >
                                    </multiselect>
                                </template>
                                <template v-slot:help
                                >The list of teachers is filtered to your school selections,
                                    it is alright if you don't know the teacher for
                                    {{ schoolYear(year) }}
                                </template>
                            </cs-form-group>
                        </div>
                        <div class="row">
                            <cs-form-group
                                    :validator="$v.information_card.address"
                                    class="col"
                                    input_id="address"
                                    label="Student primary address"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card.address.$invalid,
                      'is-invalid': $v.information_card.address.$error
                    }"
                                            class="form-control"
                                            id="address"
                                            type="text"
                                            v-model.trim.lazy="$v.information_card.address.$model"
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.address.minLength"
                                        >
                                            Must have at least
                                            {{ $v.information_card.address.$params.minLength.min }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.address.maxLength"
                                        >
                                            Must have less then
                                            {{ $v.information_card.address.$params.maxLength.max }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :validator="$v.information_card.home_phone"
                                    class="col-md-3"
                                    input_id="phone"
                                    label="Primary phone"
                            >
                                <template v-slot:input>
                                    <br/>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card.home_phone.$invalid,
                      'is-invalid': $v.information_card.home_phone.$error
                    }"
                                            class="form-control"
                                            id="phone"
                                            v-facade="'(###) ###-####'"
                                            placeholder="(###) ###-####"
                                            type="tel"
                                            v-model.trim.lazy="$v.information_card.home_phone.$model"
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.home_phone.minLength"
                                        >
                                            Must have at least
                                            {{ $v.information_card.home_phone.$params.minLength.min }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.home_phone.maxLength"
                                        >
                                            Must have less then
                                            {{ $v.information_card.home_phone.$params.maxLength.max }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                        </div>
                        <div class="row">
                            <cs-form-group
                                    :validator="$v.information_card.city"
                                    class="col-md-5"
                                    input_id="city"
                                    label="City"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card.city.$invalid,
                      'is-invalid': $v.information_card.city.$error
                    }"
                                            class="form-control"
                                            id="city"
                                            type="text"
                                            v-model.trim.lazy="$v.information_card.city.$model"
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.city.minLength"
                                        >
                                            Must have at least
                                            {{ $v.information_card.city.$params.minLength.min }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.city.maxLength"
                                        >
                                            Must have less then
                                            {{ $v.information_card.city.$params.maxLength.max }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>

                            <cs-form-group
                                    :validator="$v.information_card.state"
                                    class="col-md-3"
                                    input_id="state"
                                    label="State"
                            >
                                <template v-slot:input>
                                    <multiselect
                                            :multiple="false"
                                            :options="states"
                                            label="value"
                                            placeholder="Type to search"
                                            track-by="value"
                                            v-model="state"
                                    >
                    <span slot="noResult"
                    >Oops! No elements found. Consider changing the search
                      query.</span
                    >
                                    </multiselect>
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.state.minLength"
                                        >
                                            You have to pick a state
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :validator="$v.information_card.zip"
                                    class="col-md-3"
                                    input_id="zip"
                                    label="Zip"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card.zip.$invalid,
                      'is-invalid': $v.information_card.zip.$error
                    }"
                                            class="form-control"
                                            id="zip"
                                            type="text"
                                            v-model.trim.lazy="$v.information_card.zip.$model"
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.zip.minLength"
                                        >
                                            Must have at least
                                            {{ $v.information_card.zip.$params.minLength.min }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.zip.maxLength"
                                        >
                                            Must have less then
                                            {{ $v.information_card.zip.$params.maxLength.max }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                        </div>
                    </div>
                    <!-- end of student base -->

                    <div
                            class="list-group-item list-group-item-info text-center"
                            v-wait:visible="'information_card.saving'"
                    >
                        <cs-loading theme="success"
                        >Saving the information card progress
                        </cs-loading>
                    </div>
                    <!-- start of parent guardian -->
                    <div
                            id="guardians"
                            :class="{
              'list-group-item-danger': !guardiansValidated
            }"
                            class="list-group-item"
                    >
                        <h5>Parent/ Guardian Information</h5>
                        <div
                                :class="{ required: !$v.information_card.home.required }"
                                class="form-group row"
                        >
                            <label
                                    class="col-form-label col-md-3 offset-md-3"
                                    for="lives_with"
                            >Student lives with</label
                            >
                            <div class="col-md-4">
                                <multiselect
                                        :multiple="false"
                                        :options="lives_with_options"
                                        id="lives_with"
                                        label="value"
                                        selectLabel=""
                                        track-by="value"
                                        v-model="lives_with"
                                />
                            </div>
                        </div>

                        <div class="row">
                            <cs-form-group
                                    :validator="$v.information_card.mother_guardian_first"
                                    class="col-md-4"
                                    input_id="mother_guardian_first"
                                    label="Parent/Guardian First Name"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card.mother_guardian_first
                        .$invalid,
                      'is-invalid':
                        $v.information_card.mother_guardian_first.$error
                    }"
                                            class="form-control"
                                            id="mother_guardian_first"
                                            type="text"
                                            v-model.trim.lazy="
                      $v.information_card.mother_guardian_first.$model
                    "
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="
                        !$v.information_card.mother_guardian_first.minLength
                      "
                                        >
                                            Must have at least
                                            {{
                                            $v.information_card.mother_guardian_first.$params
                                                .minLength.min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="
                        !$v.information_card.mother_guardian_first.maxLength
                      "
                                        >
                                            Must have less then
                                            {{
                                            $v.information_card.mother_guardian_first.$params
                                                .maxLength.max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :validator="$v.information_card.mother_guardian_last"
                                    class="col-md-4"
                                    input_id="mother_guardian_last"
                                    label="Last Name"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card.mother_guardian_last
                        .$invalid,
                      'is-invalid':
                        $v.information_card.mother_guardian_last.$error
                    }"
                                            class="form-control"
                                            id="mother_guardian_last"
                                            type="text"
                                            v-model.trim.lazy="
                      $v.information_card.mother_guardian_last.$model
                    "
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.mother_guardian_last.minLength"
                                        >
                                            Must have at least
                                            {{
                                            $v.information_card.mother_guardian_last.$params
                                                .minLength.min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.mother_guardian_last.maxLength"
                                        >
                                            Must have less then
                                            {{
                                            $v.information_card.mother_guardian_last.$params
                                                .maxLength.max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :validator="$v.information_card.mother_guardian_email"
                                    class="col-md-4"
                                    input_id="mother_guardian_email"
                                    label="Email"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card.mother_guardian_email
                        .$invalid,
                      'is-invalid':
                        $v.information_card.mother_guardian_email.$error
                    }"
                                            class="form-control"
                                            id="mother_guardian_email"
                                            type="text"
                                            v-model.trim.lazy="
                      $v.information_card.mother_guardian_email.$model
                    "
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.mother_guardian_email.email"
                                        >
                                            Invalid Email.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                        </div>
                        <div class="row">
                            <cs-form-group
                                    :validator="$v.information_card.mother_guardian_address"
                                    class="col"
                                    input_id="address"
                                    label="Parent/Guardian address"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card.mother_guardian_address
                        .$invalid,
                      'is-invalid':
                        $v.information_card.mother_guardian_address.$error
                    }"
                                            class="form-control"
                                            id="mother_guardian_address"
                                            type="text"
                                            v-model.trim.lazy="
                      $v.information_card.mother_guardian_address.$model
                    "
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="
                        !$v.information_card.mother_guardian_address.minLength
                      "
                                        >
                                            Must have at least
                                            {{
                                            $v.information_card.mother_guardian_address.$params
                                                .minLength.min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="
                        !$v.information_card.mother_guardian_address.maxLength
                      "
                                        >
                                            Must have less then
                                            {{
                                            $v.information_card.mother_guardian_address.$params
                                                .maxLength.max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :validator="$v.information_card.mother_guardian_phone"
                                    class="col-md-3"
                                    input_id="mother_guardian_phone"
                                    label="Primary phone"
                            >
                                <template v-slot:input>
                                    <br/>
                                    <input
                                            type="text"
                                            v-facade="'(###) ###-####'"
                                            placeholder="Phone"
                                            v-model.trim.lazy="
                      $v.information_card.mother_guardian_phone.$model
                    "
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="
                        !$v.information_card.mother_guardian_phone.minLength
                      "
                                        >
                                            Must have at least
                                            {{
                                            $v.information_card.mother_guardian_phone.$params
                                                .minLength.min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="
                        !$v.information_card.mother_guardian_phone.maxLength
                      "
                                        >
                                            Must have less then
                                            {{
                                            $v.information_card.mother_guardian_phone.$params
                                                .maxLength.max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                        </div>
                        <div class="row">
                            <cs-form-group
                                    :validator="$v.information_card.mother_guardian_city"
                                    class="col-md-4"
                                    input_id="mother_guardian_city"
                                    label="City"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card.mother_guardian_city
                        .$invalid,
                      'is-invalid':
                        $v.information_card.mother_guardian_city.$error
                    }"
                                            class="form-control"
                                            id="mother_guardian_city"
                                            type="text"
                                            v-model.trim.lazy="
                      $v.information_card.mother_guardian_city.$model
                    "
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.mother_guardian_city.minLength"
                                        >
                                            Must have at least
                                            {{
                                            $v.information_card.mother_guardian_city.$params
                                                .minLength.min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.mother_guardian_city.maxLength"
                                        >
                                            Must have less then
                                            {{
                                            $v.information_card.mother_guardian_city.$params
                                                .maxLength.max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>

                            <cs-form-group
                                    :validator="$v.information_card.mother_guardian_state"
                                    class="col-md-2"
                                    input_id="mother_guardian_state"
                                    label="State"
                            >
                                <template v-slot:input>
                                    <multiselect
                                            :multiple="false"
                                            :options="states"
                                            label="value"
                                            placeholder="Type to search"
                                            track-by="value"
                                            v-model="guardianState"
                                    >
                    <span slot="noResult"
                    >Oops! No elements found. Consider changing the search
                      query.</span
                    >
                                    </multiselect>
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.mother_guardian_state.$invalid"
                                        >
                                            You need to select a state
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :validator="$v.information_card.mother_guardian_zip.zip"
                                    class="col-md-3"
                                    input_id="zip"
                                    label="Zip"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card.mother_guardian_zip
                        .$invalid,
                      'is-invalid':
                        $v.information_card.mother_guardian_zip.$error
                    }"
                                            class="form-control"
                                            id="mother_guardian_zip"
                                            type="text"
                                            v-model.trim.lazy="
                      $v.information_card.mother_guardian_zip.$model
                    "
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.mother_guardian_zip.minLength"
                                        >
                                            Must have at least
                                            {{
                                            $v.information_card.mother_guardian_zip.$params
                                                .minLength.min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.mother_guardian_zip.maxLength"
                                        >
                                            Must have less then
                                            {{
                                            $v.information_card.mother_guardian_zip.$params
                                                .maxLength.max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :validator="$v.information_card.mother_guardian_cell"
                                    class="col-md-3"
                                    input_id="mother_guardian_cell"
                                    label="Cell phone"
                            >
                                <template v-slot:input>
                                    <br/>
                                    <input
                                            type="text"
                                            v-facade="'(###) ###-####'"
                                            placeholder="Phone"
                                            v-model.trim.lazy="
                      $v.information_card.mother_guardian_cell.$model
                    "
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.mother_guardian_cell.minLength"
                                        >
                                            Must have at least
                                            {{
                                            $v.information_card.mother_guardian_cell.$params
                                                .minLength.min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.mother_guardian_cell.maxLength"
                                        >
                                            Must have less then
                                            {{
                                            $v.information_card.mother_guardian_cell.$params
                                                .maxLength.max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                        </div>
                        <hr/>
                        <div class="row">
                            <cs-form-group
                                    :validator="$v.information_card.father_guardian_first"
                                    class="col-md-4"
                                    input_id="father_guardian_first"
                                    label="Second Parent/Guardian First Name"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card.father_guardian_first
                        .$invalid,
                      'is-invalid':
                        $v.information_card.father_guardian_first.$error
                    }"
                                            class="form-control"
                                            id="father_guardian_first"
                                            type="text"
                                            v-model.trim.lazy="
                      $v.information_card.father_guardian_first.$model
                    "
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="
                        !$v.information_card.father_guardian_first.minLength
                      "
                                        >
                                            Must have at least
                                            {{
                                            $v.information_card.father_guardian_first.$params
                                                .minLength.min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="
                        !$v.information_card.father_guardian_first.maxLength
                      "
                                        >
                                            Must have less then
                                            {{
                                            $v.information_card.father_guardian_first.$params
                                                .maxLength.max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :validator="$v.information_card.father_guardian_last"
                                    class="col-md-4"
                                    input_id="father_guardian_last"
                                    label="Last Name"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card.father_guardian_last
                        .$invalid,
                      'is-invalid':
                        $v.information_card.father_guardian_last.$error
                    }"
                                            class="form-control"
                                            id="father_guardian_last"
                                            type="text"
                                            v-model.trim.lazy="
                      $v.information_card.father_guardian_last.$model
                    "
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.father_guardian_last.minLength"
                                        >
                                            Must have at least
                                            {{
                                            $v.information_card.father_guardian_last.$params
                                                .minLength.min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.father_guardian_last.maxLength"
                                        >
                                            Must have less then
                                            {{
                                            $v.information_card.father_guardian_last.$params
                                                .maxLength.max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :validator="$v.information_card.father_guardian_email"
                                    class="col-md-4"
                                    input_id="father_guardian_email"
                                    label="Email"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card.father_guardian_email
                        .$invalid,
                      'is-invalid':
                        $v.information_card.father_guardian_email.$error
                    }"
                                            class="form-control"
                                            id="father_guardian_email"
                                            type="text"
                                            v-model.trim.lazy="
                      $v.information_card.father_guardian_email.$model
                    "
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.father_guardian_email.email"
                                        >
                                            Invalid Email.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                        </div>
                        <div class="row">
                            <cs-form-group
                                    :validator="$v.information_card.father_guardian_address"
                                    class="col"
                                    input_id="address"
                                    label="Parent/Guardian address"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card.father_guardian_address
                        .$invalid,
                      'is-invalid':
                        $v.information_card.father_guardian_address.$error
                    }"
                                            class="form-control"
                                            id="father_guardian_address"
                                            type="text"
                                            v-model.trim.lazy="
                      $v.information_card.father_guardian_address.$model
                    "
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="
                        !$v.information_card.father_guardian_address.minLength
                      "
                                        >
                                            Must have at least
                                            {{
                                            $v.information_card.father_guardian_address.$params
                                                .minLength.min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="
                        !$v.information_card.father_guardian_address.maxLength
                      "
                                        >
                                            Must have less then
                                            {{
                                            $v.information_card.father_guardian_address.$params
                                                .maxLength.max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :validator="$v.information_card.father_guardian_phone"
                                    class="col-md-3"
                                    input_id="father_guardian_phone"
                                    label="Primary phone"
                            >
                                <template v-slot:input>
                                    <br/>
                                    <input
                                            type="text"
                                            v-facade="'(###) ###-####'"
                                            placeholder="Phone"
                                            v-model.trim.lazy="
                      $v.information_card.father_guardian_phone.$model
                    "
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="
                        !$v.information_card.father_guardian_phone.minLength
                      "
                                        >
                                            Must have at least
                                            {{
                                            $v.information_card.father_guardian_phone.$params
                                                .minLength.min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="
                        !$v.information_card.father_guardian_phone.maxLength
                      "
                                        >
                                            Must have less then
                                            {{
                                            $v.information_card.father_guardian_phone.$params
                                                .maxLength.max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                        </div>
                        <div class="row">
                            <cs-form-group
                                    :validator="$v.information_card.father_guardian_city"
                                    class="col-md-4"
                                    input_id="father_guardian_city"
                                    label="City"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card.father_guardian_city
                        .$invalid,
                      'is-invalid':
                        $v.information_card.father_guardian_city.$error
                    }"
                                            class="form-control"
                                            id="father_guardian_city"
                                            type="text"
                                            v-model.trim.lazy="
                      $v.information_card.father_guardian_city.$model
                    "
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.father_guardian_city.minLength"
                                        >
                                            Must have at least
                                            {{
                                            $v.information_card.father_guardian_city.$params
                                                .minLength.min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.father_guardian_city.maxLength"
                                        >
                                            Must have less then
                                            {{
                                            $v.information_card.father_guardian_city.$params
                                                .maxLength.max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>

                            <cs-form-group
                                    :validator="$v.information_card.father_guardian_state"
                                    class="col-md-2"
                                    input_id="father_guardian_state"
                                    label="State"
                            >
                                <template v-slot:input>
                                    <multiselect
                                            :multiple="false"
                                            :options="states"
                                            label="value"
                                            placeholder="Type to search"
                                            track-by="value"
                                            v-model="secondGuardianState"
                                    >
                    <span slot="noResult"
                    >Oops! No elements found. Consider changing the search
                      query.</span
                    >
                                    </multiselect>
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.father_guardian_state.$invalid"
                                        >
                                            You need to select a state
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :validator="$v.information_card.father_guardian_zip"
                                    class="col-md-3"
                                    input_id="zip"
                                    label="Zip"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card.father_guardian_zip
                        .$invalid,
                      'is-invalid':
                        $v.information_card.father_guardian_zip.$error
                    }"
                                            class="form-control"
                                            id="father_guardian_zip"
                                            type="text"
                                            v-model.trim.lazy="
                      $v.information_card.father_guardian_zip.$model
                    "
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    father_guardian_city
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :validator="$v.information_card.father_guardian_cell"
                                    class="col-md-3"
                                    input_id="father_guardian_cell"
                                    label="Cell phone"
                            >
                                <template v-slot:input>
                                    <br/>
                                    <input
                                            type="text"
                                            v-facade="'(###) ###-####'"
                                            placeholder="Phone"
                                            v-model.trim.lazy="
                      $v.information_card.father_guardian_cell.$model
                    "
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.father_guardian_cell.minLength"
                                        >
                                            Must have at least
                                            {{
                                            $v.information_card.father_guardian_cell.$params
                                                .minLength.min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.father_guardian_cell.maxLength"
                                        >
                                            Must have less then
                                            {{
                                            $v.information_card.father_guardian_cell.$params
                                                .maxLength.max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                        </div>
                    </div>
                    <!-- end of parent/ guardian -->
                    <!-- start of health/ insurance -->
                    <div id="health"
                         :class="{
              'list-group-item-danger': !healthValidated
            }"
                         class="list-group-item"
                    >
                        <h5>Health and Insurance Information</h5>
                        <div class="row">
                            <cs-form-group
                                    :validator="$v.information_card.insurance_carrier"
                                    class="col-md-4"
                                    input_id="insurance_carrier"
                                    label="Insurance Carrier"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card.insurance_carrier
                        .$invalid,
                      'is-invalid': $v.information_card.insurance_carrier.$error
                    }"
                                            class="form-control"
                                            id="insurance_carrier"
                                            type="text"
                                            v-model.trim.lazy="
                      $v.information_card.insurance_carrier.$model
                    "
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.insurance_carrier.minLength"
                                        >
                                            Must have at least
                                            {{
                                            $v.information_card.insurance_carrier.$params.minLength
                                                .min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.insurance_carrier.maxLength"
                                        >
                                            Must have less then
                                            {{
                                            $v.information_card.insurance_carrier.$params.maxLength
                                                .max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :validator="$v.information_card.health_care_provider"
                                    class="col-md-4"
                                    input_id="health_care_provider"
                                    label="Health Care Provider"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card.health_care_provider
                        .$invalid,
                      'is-invalid':
                        $v.information_card.health_care_provider.$error
                    }"
                                            class="form-control"
                                            id="health_care_provider"
                                            type="text"
                                            v-model.trim.lazy="
                      $v.information_card.health_care_provider.$model
                    "
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.health_care_provider.minLength"
                                        >
                                            Must have at least
                                            {{
                                            $v.information_card.health_care_provider.$params
                                                .minLength.min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.health_care_provider.maxLength"
                                        >
                                            Must have less then
                                            {{
                                            $v.information_card.health_care_provider.$params
                                                .maxLength.max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :validator="$v.information_card.health_care_provider_phone"
                                    class="col-md-3"
                                    input_id="health_care_phone"
                                    label="Phone"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card
                        .health_care_provider_phone.$invalid,
                      'is-invalid':
                        $v.information_card.health_care_provider_phone.$error
                    }"
                                            class="form-control"
                                            id="health_care_phone"
                                            v-facade="'(###) ###-####'"
                                            placeholder="(###) ###-####"
                                            type="tel"
                                            v-model.trim.lazy="
                      $v.information_card.health_care_provider_phone.$model
                    "
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="
                        !$v.information_card.health_care_provider_phone
                          .minLength
                      "
                                        >
                                            Must have at least
                                            {{
                                            $v.information_card.health_care_provider_phone.$params
                                                .minLength.min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="
                        !$v.information_card.health_care_provider_phone
                          .maxLength
                      "
                                        >
                                            Must have less then
                                            {{
                                            $v.information_card.health_care_provider_phone.$params
                                                .maxLength.max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                        </div>
                    </div>
                    <!-- end of health/insurance base -->
                    <!-- start of additional  -->
                    <div
                            id="additional-info"
                            :class="{
              'list-group-item-danger': !additionalInfoValidated
            }"
                            class="list-group-item"
                    >
                        <h5>Additional Information</h5>
                        <div class="row">
                            <cs-form-group
                                    :validator="$v.information_card.has_iep"
                                    class="col-md-3"
                                    input_id="has_iep"
                                    label="IEP"
                            >
                                <template v-slot:input>
                                    <br/>
                                    <div class="form-check form-check-inline">
                                        <input
                                                :value="true"
                                                class="form-check-input"
                                                id="has_iep_yes"
                                                name="has_iep"
                                                type="radio"
                                                v-model="$v.information_card.has_iep.$model"
                                        />
                                        <label class="form-check-label" for="has_iep_yes"
                                        >Has IEP</label
                                        >
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                                :value="false"
                                                class="form-check-input"
                                                id="has_iep_no"
                                                name="has_iep_no"
                                                type="radio"
                                                v-model="$v.information_card.has_iep.$model"
                                        />
                                        <label class="form-check-label" for="has_iep_no"
                                        >Does not have IEP</label
                                        >
                                    </div>
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.has_iep.$invalid"
                                        >
                                            An answer is required for <strong>has IEP</strong>
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :validator="$v.information_card.has_504"
                                    class="col-md-3"
                                    input_id="has_504"
                                    label="504 Plan"
                            >
                                <template v-slot:input>
                                    <br/>
                                    <div class="form-check form-check-inline">
                                        <input
                                                :value="true"
                                                class="form-check-input"
                                                id="has_504_yes"
                                                name="has_504"
                                                type="radio"
                                                v-model="$v.information_card.has_504.$model"
                                        />
                                        <label class="form-check-label" for="has_504_yes"
                                        >Has 504</label
                                        >
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                                :value="false"
                                                class="form-check-input"
                                                id="has_504_no"
                                                name="has_504_no"
                                                type="radio"
                                                v-model="$v.information_card.has_504.$model"
                                        />
                                        <label class="form-check-label" for="has_504_no"
                                        >Does not have 504</label
                                        >
                                    </div>
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.has_504.$invalid"
                                        >
                                            An answer is required for <strong>has 504</strong>
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :validator="$v.information_card.additional_comments"
                                    class="col"
                                    input_id="additional_comments"
                                    label="Information about plans"
                            >
                                <template v-slot:input>
                  <textarea
                          :class="{
                      'is-valid': !$v.information_card.additional_comments
                        .$invalid,
                      'is-invalid':
                        $v.information_card.additional_comments.$error
                    }"
                          class="form-control"
                          id="additional_comments"
                          type="textarea"
                          v-model.trim.lazy="
                      $v.information_card.additional_comments.$model
                    "
                  />
                                </template>
                                <template v-slot:help>
                                    Provide any information you would like the Community Schools
                                    programs to be aware of in regards IEP or 504 plans. Specific
                                    programs may contact you regarding any additional questions.
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.additional_comments.minLength"
                                        >
                                            Information about plans must have at least
                                            {{
                                            $v.information_card.additional_comments.$params
                                                .minLength.min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.additional_comments.maxLength"
                                        >
                                            Information about plans must have less then
                                            {{
                                            $v.information_card.additional_comments.$params
                                                .maxLength.max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                        </div>

                        <div class="row">
                            <cs-form-group
                                    :validator="$v.information_card.individual_called"
                                    class="col-md-4"
                                    input_input_id="individual_called"
                                    label="Who to call first (first and last name)"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card.individual_called
                        .$invalid,
                      'is-invalid': $v.information_card.individual_called.$error
                    }"
                                            class="form-control"
                                            id="individual_called"
                                            type="text"
                                            v-model.trim.lazy="
                      $v.information_card.individual_called.$model
                    "
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.individual_called.minLength"
                                        >
                                            Must have at least
                                            {{
                                            $v.information_card.individual_called.$params.minLength
                                                .min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.individual_called.maxLength"
                                        >
                                            Must have less then
                                            {{
                                            $v.information_card.individual_called.$params.maxLength
                                                .max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :validator="$v.information_card.telephone_authorization_code"
                                    class="col-md-4"
                                    input_id="telephone_authorization_code"
                                    label="Family Codeword"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card
                        .telephone_authorization_code.$invalid,
                      'is-invalid':
                        $v.information_card.telephone_authorization_code.$error
                    }"
                                            class="form-control"
                                            id="telephone_authorization_code"
                                            type="text"
                                            v-model.trim.lazy="
                      $v.information_card.telephone_authorization_code.$model
                    "
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="
                        !$v.information_card.telephone_authorization_code
                          .minLength
                      "
                                        >
                                            Must have at least
                                            {{
                                            $v.information_card.telephone_authorization_code.$params
                                                .minLength.min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="
                        !$v.information_card.telephone_authorization_code
                          .maxLength
                      "
                                        >
                                            Must have less then
                                            {{
                                            $v.information_card.telephone_authorization_code.$params
                                                .maxLength.max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :validator="$v.information_card.not_to_remove"
                                    class="col-md-4"
                                    inpu_id="not_to_remove"
                                    label="Not to remove from Programs"
                            >
                                <template v-slot:input>
                                    <input
                                            :class="{
                      'is-valid': !$v.information_card.not_to_remove.$invalid,
                      'is-invalid': $v.information_card.not_to_remove.$error
                    }"
                                            class="form-control"
                                            id="not_to_remove"
                                            type="text"
                                            v-model.trim.lazy="$v.information_card.not_to_remove.$model"
                                    />
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.not_to_remove.minLength"
                                        >
                                            Must have at least
                                            {{
                                            $v.information_card.not_to_remove.$params.minLength.min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.not_to_remove.maxLength"
                                        >
                                            Must have less then
                                            {{
                                            $v.information_card.not_to_remove.$params.maxLength.max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                        </div>
                        <div class="row">
                            <cs-form-group
                                    :validator="$v.information_card.custody_papers_filed"
                                    class="col-md-3"
                                    input_id="custody_papers_filed"
                                    label="Custody papers filed with district"
                            >
                                <template v-slot:input>
                                    <br/>
                                    <div class="form-check form-check-inline">
                                        <input
                                                :value="true"
                                                class="form-check-input"
                                                id="custody_papers_filed_yes"
                                                name="custody_papers_filed"
                                                type="radio"
                                                v-model="$v.information_card.custody_papers_filed.$model"
                                        />
                                        <label
                                                class="form-check-label"
                                                for="custody_papers_filed_yes"
                                        >Papers filed</label
                                        >
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                                :value="false"
                                                class="form-check-input"
                                                id="custody_papers_filed_no"
                                                name="custody_papers_filed_no"
                                                type="radio"
                                                v-model="$v.information_card.custody_papers_filed.$model"
                                        />
                                        <label
                                                class="form-check-label"
                                                for="custody_papers_filed_no"
                                        >No papers filed</label
                                        >
                                    </div>
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.custody_papers_filed.$invalid"
                                        >
                                            An answer is required for
                                            <strong>Filing Custody Papers</strong>
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                            <cs-form-group
                                    :validator="$v.information_card.special_instructions"
                                    class="col"
                                    input_id="additional_comments"
                                    label="Special instructions or any additional information"
                            >
                                <template v-slot:input>
                  <textarea
                          :class="{
                      'is-valid': !$v.information_card.special_instructions
                        .$invalid,
                      'is-invalid':
                        $v.information_card.special_instructions.$error
                    }"
                          class="form-control"
                          id="special_instructions"
                          rows="4"
                          type="textarea"
                          v-model.trim.lazy="
                      $v.information_card.special_instructions.$model
                    "
                  />
                                </template>
                                <template v-slot:help>
                                    Provide any information you would like the community schools
                                    program to be aware of for your student. Specific programs may
                                    contact you regarding any additional questions.
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.special_instructions.minLength"
                                        >
                                            Special instructions must have at least
                                            {{
                                            $v.information_card.special_instructions.$params
                                                .minLength.min
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.special_instructions.maxLength"
                                        >
                                            Special Instructions must have less then
                                            {{
                                            $v.information_card.special_instructions.$params
                                                .maxLength.max
                                            }}
                                            characters.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                        </div>
                    </div>
                    <!-- end of additional base -->
                    <div
                            class="list-group-item list-group-item-info text-center"
                            v-wait:visible="'information_card.saving'"
                    >
                        <cs-loading theme="success"
                        >Saving the information card progress
                        </cs-loading>
                    </div>
                    <!-- start of emergency contacts -->
                    <transition mode="out-in">
                        <div
                                id="contacts"
                                :class="{
                'list-group-item-danger': !contactsComplete
              }"
                                class="list-group-item"
                                key="contacts"
                                v-if="hasId"
                        >
                            <contacts
                                    :contacts="information_card.contacts"
                                    :information_card="information_card"
                                    :member_id="member_id"
                            >You have count contacts
                            </contacts>
                        </div>
                        <div
                                class="list-group-item list-group-item-danger"
                                key="no-contacts"
                                v-else
                        >
                            <h3>Emergency Contacts</h3>
                            <p class="lead">
                                You have to enter the minimum amount of information about the
                                student before adding emergency contacts
                            </p>
                        </div>
                    </transition>
                    <!-- end of emergency contacts -->
                    <!-- start of medicals -->
                    <transition mode="out-in">
                        <div
                                id="medical"
                                :class="{
                'list-group-item-danger':
                  $v.information_card.medical_complete.$invalid
              }"
                                class="list-group-item"
                                v-if="hasId"
                        >
                            <medicals
                                    :information_card_id="information_card.id"
                                    :medical_complete="information_card.medical_complete"
                                    :medicals="information_card.medicals"
                                    :member_id="member_id"
                                    :year="year"
                                    @medical-count="medicalCount"
                                    @no-medical="checkNoMedical"
                            >You have medicals
                            </medicals>
                            <cs-form-group
                                    :validator="$v.information_card.medical_complete"
                                    input_id="medical_complete"
                                    label="Confirm that all Medical Conditions and Allergies are added"
                                    v-if="showMedicalsCheckbox"
                            >
                                <template v-slot:input>
                                    <br/>
                                    <div class="form-check form-check-inline">
                                        <input
                                                class="form-check-input"
                                                id="medical_complete"
                                                name="medical_complete"
                                                type="checkbox"
                                                v-model="$v.information_card.medical_complete.$model"
                                                value="male"
                                        />
                                        <label class="form-check-label" for="medical_complete"
                                        >Medical Conditions and Allergies Complete</label
                                        >
                                    </div>
                                </template>
                                <template v-slot:invalid-feedback>
                                    <transition mode="out-in">
                                        <div
                                                class="error"
                                                v-if="!$v.information_card.medical_complete.$invalid"
                                        >
                                            Please confirm that medical/allergy information is
                                            complete.
                                        </div>
                                    </transition>
                                </template>
                            </cs-form-group>
                        </div>

                        <div
                                class="list-group-item list-group-item-danger"
                                key="no-medicals"
                                v-else
                        >
                            <h3>Medical and Allergy Information</h3>
                            <p class="lead">
                                You have to enter the minimum amount of information about the
                                student before adding allergy and medical information
                            </p>
                        </div>
                    </transition>
                    <!-- end of medicals -->
                </div>
                <div class="list-group-item list-group-item-warning" v-if="readyToSign">
                    <p class="lead">
                        Sign to confirm your entry for {{ information_card.name }} for
                        {{ schoolYear(year) }}
                    </p>
                    <p>
                        Your signature below indicates that all of the information listed
                        for {{ information_card.name }} is accurate and complete for
                        {{ schoolYear(year) }}. Your signature also confirms that you have
                        no more medial or allergy information to add and that you have
                        completed all the emergency and other contact information.
                    </p>
                    <cs-form-group
                            :validator="$v.information_card.photo_release"
                            input_id="photo_release"
                            label="Photo/ Video Release"
                    >
                        <template v-slot:input>
                            <br/>
                            <div class="form-check form-check-inline">
                                <input
                                        class="form-check-input"
                                        id="photo_release"
                                        name="photo_release"
                                        type="checkbox"
                                        v-model="$v.information_card.photo_release.$model"
                                />
                                <label class="form-check-label" for="photo_release">
                                    Permission is also given for photographs or video segments of
                                    {{ information_card.name }} participating in Community Schools
                                    activities for future publication in print or media
                                </label>
                            </div>
                        </template>
                        <template v-slot:invalid-feedback>
                            <transition mode="out-in">
                                <div
                                        class="error"
                                        v-if="!$v.information_card.photo_release.$invalid"
                                >
                                    Photo release should be entered.
                                </div>
                            </transition>
                        </template>
                    </cs-form-group>
                    <div class="row">
                        <div
                                class="col-lg-8 col-md-10 mt-2 mx-auto border border-dark bg-dark-light"
                        >
                            <vue-signature-pad
                                    :options="options"
                                    height="300px"
                                    id="signature"
                                    ref="signaturePad"
                                    saveType="image/svg+xml"
                                    width="100%"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div
                                class="col-lg-8 col-md-10 mt-2 mx-auto d-flex justify-content-around"
                        >
                            <cs-button
                                    :isOutline="true"
                                    @handle-click.prevent="undo"
                                    iconLeft="undo"
                                    type="primary"
                            >
                                Undo
                            </cs-button>
                            <cs-button
                                    :isOutline="true"
                                    @handle-click.prevent="clear"
                                    iconLeft="clear"
                                    type="warning"
                            >
                                Clear
                            </cs-button>
                        </div>
                    </div>
                </div>

                <div
                        :class="{
            'list-group-danger': !isValidated
          }"
                        class="list-group-item"
                        v-else
                >
                    <p class="lead">
                        Please make sure all the above required information is completed
                        before you can sign and complete the information card
                    </p>
                    <div class="list-group">
                        <button
                                v-if="!studentInfoValidated"
                                class="list-group-item list-group-item-danger"
                                v-scroll-to="'#student-info'">
                            Complete Student Information Section
                        </button>
                        <button
                                v-if="!guardiansValidated"
                                class="list-group-item list-group-item-danger"
                                v-scroll-to="'#guardians'">
                            Complete Parent/Guardian Information Section
                        </button>
                        <button
                                v-if="!additionalInfoValidated"
                                class="list-group-item list-group-item-danger"
                                v-scroll-to="'#additional-info'">
                            Complete Additional Information Section
                        </button>
                        <button
                                v-if="!contactsComplete"
                                class="list-group-item list-group-item-danger"
                                v-scroll-to="'#contacts'">
                            Add Emergency Contacts
                        </button>
                        <button
                                v-if="$v.information_card.medical_complete.$invalid"
                                class="list-group-item list-group-item-danger" v-scroll-to="'#medical'">
                            Complete Medical and Allergy Information
                        </button>
                    </div>
                </div>
                <div v-if="canEditProgramYear  && isEmpty(selectedProgram)" class="list-group-item">
                    <h5>Select Program and Year</h5>
                    <div class="row">
                        <div
                                v-wait:visible="'programs.load'"
                                class="alert alert-warning col-md-6"
                        >
                            <cs-loading>
                                Loading programs
                            </cs-loading>
                        </div>
                        <cs-form-group
                                v-wait:hidden="'programs.load'"
                                :label="'Program ' + year + '-' + (year + 1) + ' (optional)'"
                                class="col-md-6"
                                input_id="program_slug"
                                help="This will give an option to start signing up for the program when you complete information card"
                        >
                            <template v-slot:input>
                                <multiselect
                                        :allowEmpty="false"
                                        :multiple="false"
                                        :options="programs"
                                        label="name"
                                        placeholder="Type to search"
                                        track-by="slug"
                                        v-model="selectedProgram"
                                >
                    <span slot="noResult"
                    >Oops! No elements found. Consider changing the search
                      query.</span
                    >
                                </multiselect>
                            </template>
                        </cs-form-group>
                        <cs-form-group
                                :label="'Year for Information Card'"
                                class="col-md-6"
                                input_id="year"
                        >
                            <template v-slot:input>
                                <multiselect
                                        :allowEmpty="false"
                                        :multiple="false"
                                        :options="years"
                                        label="value"
                                        placeholder="Type to search"
                                        track-by="value"
                                        v-model="selectedYear"
                                >
                    <span slot="noResult"
                    >Oops! No elements found. Consider changing the search
                      query.</span
                    >
                                </multiselect>
                            </template>
                        </cs-form-group>
                    </div>
                </div>
                <div class="card-footer">
                    <cs-button
                            :disabled="!isValidated"
                            :loading="$wait.is('information_card.saving')"
                            @handle-click="save($event)"
                            iconLeft="save"
                            type="primary"
                    >
                        {{ saveButtonText }}
                    </cs-button>
                    <cs-button
                            :disabled="!isValidated"
                            :loading="$wait.is('information_card.saving')"
                            @click="save($event, {addAnotherStudent: true})"
                            iconLeft="add-student"
                            type="primary"
                    >Save and add another student
                    </cs-button>
                    <cs-button
                            v-if="!isEmpty(selectedProgram)"
                            :disabled="!isValidated"
                            :loading="$wait.is('information_card.saving')"
                            @click="save($event, {goToProgram: selectedProgram })"
                            iconLeft="add-student"
                            type="primary"
                    >Save and go to {{ selectedProgram.name }}
                    </cs-button>
                    <cs-button
                            @handle-click="cancel($event)"
                            iconLeft="cancel"
                            type="danger"
                    >{{ returnTo === '' ? 'Cancel' : 'Return' }}
                    </cs-button>
                    <span>
            <cs-loading
                    theme="success"
                    v-wait:visible="'information_card.saving'"
            >
              Saving the information card progress
            </cs-loading>
          </span>
                    <cs-button
                            @handle-click="save($event)"
                            iconLeft="save"
                            type="warning"
                            minRole="site"
                            :userRole="authMember.role"
                            class="float-right"
                    >Manager Save
                    </cs-button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { API } from '@/utilities/http-common'

import { validationMixin } from 'vuelidate'
import { mapWaitingGetters } from 'vue-wait'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'
import range from 'lodash/range'
import moment from 'moment'
import { facade } from 'vue-input-facade'
import { VueSignaturePad } from 'vue-signature-pad'

import Member from '@/models/Member'
import Teacher from '@/models/Teacher'
import InformationCard from '@/models/InformationCard'
import Program from '@/models/Program'
import Contacts from './Contacts'
import Medicals from './Medicals'

import InformationCardContact from '@/models/InformationCardContact'

import { dateString, schoolYear, timeString } from '@/utilities/dateFormatters'
import { mapGetters } from 'vuex'
import subMonths from 'date-fns/subMonths'
import parseISO from 'date-fns/parseISO'
import parse from 'date-fns/parse'
import { addHours } from 'date-fns'
import { isDateBetween } from '@/utilities/utilities'

export default {
  name: 'InformationCardEdit',
  directives: { facade },
  components: {
    Contacts,
    Medicals,
    VueSignaturePad
  },
  mixins: [validationMixin],
  data () {
    return {
      contactCountMinimum: 2,
      options: {
        penColor: '#000000',
        onBegin: this.onBegin,
        onEnd: this.onEnd
      },
      throttleSave: null,
      debounceSave: null,
      enrollment_types: [],
      information_card: new InformationCard(this.instance),
      lives_with_options: [
        { name: 'm', value: 'First Parent/Guardian' },
        { name: 'f', value: 'Second Parent/Guardian' },
        { name: 'b', value: 'Both Parent/Guardians' }
      ],
      // these have mappings that need to be done manually, set by mounted
      showMedicalsCheckbox: false
    }
  },
  props: {
    instance: {
      type: Object,
      default: () => {}
    },
    member_id: {
      type: [Number, String],
      required: true
    },
    auth_id: {
      type: [Number, String],
      default: null
    },
    id: {
      type: [String, Number],
      required: true
    },
    year: {
      type: Number,
      required: true
    },
    program: {
      type: Object,
      required: false,
      default: null
    },
    programSlug: {
      type: String,
      required: false,
      default: ''
    },
    returnTo: {
      type: String,
      required: true,
      default: ''
    },
    canEditProgramYear: {
      type: Boolean,
      required: false,
      default: false
    },
    enrollmentTypes: {
      type: Object,
      required: true
    },
    sourceInformationCard: {
      type: Object,
      required: true
    }
  },
  created: function () {
    this.throttleSave = throttle(this.save, 3000)
    this.debounceSave = debounce(this.save, 1000)
  },
  mounted () {
    if (isEmpty(this.information_card.school_year) && this.year > 0) {
      this.information_card.school_year = this.year
    }
    this.$v.$touch()
    this.$nextTick(function () {
      if (this.countOfMedicals > 0) {
        this.showMedicalsCheckbox = true
      }
    })
    if (typeof this.id === 'undefined' || this.id === -1) {
      // this is probably a new information card so pre-fill some information
      this.information_card.mother_guardian_first = this.member.first
      this.information_card.mother_guardian_last = this.member.last
      this.information_card.mother_guardian_email = this.member.email
    }
  },
  methods: {
    schoolYear,
    dateString,
    timeString,
    checkNoMedical () {
      this.information_card.medical_complete = true
      this.showMedicalsCheckbox = true
    },
    stateObject (value) {
      return this.states.find(function (state) {
        if (value === state.key) {
          return true
        } else if (value === state.value) {
          return true
        }
      })
    },
    programObject (value) {
      return this.programs.find(function (state) {
        if (value === state.slug) {
          return true
        }
      })
    },
    medicalCount (data) {
      if (data.medicalCount > 0) {
        this.showMedicalsCheckbox = true
      }
    },
    birthdayChange (selectedDates) {
      console.warn('InformationCardEdit.birthdayChange', selectedDates)
      if (selectedDates.length > 0) {
        const date = moment(selectedDates[0])
        this.information_card.date_of_birth = date.toISOString()
      } else {
        this.information_card.date_of_birth = null
      }
      this.$v.information_card.date_of_birth.$touch()
    },
    isEmpty (value) {
      return isEmpty(value)
    },
    onBegin () {},
    onEnd () {
      // eslint-disable-next-line no-unused-vars
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature('image/svg+xml')
      this.information_card.signature_data = data
    },
    undo () {
      this.$refs.signaturePad.undoSignature()
    },
    cancel (e) {
      if (!isEmpty(e)) {
        // stop the propagation of the click
        e.preventDefault()
      }
      this.$emit('cancel', {
        information_card_id: this.information_card.id,
        student_id: this.information_card.student_id
      })
    },
    /**
     *
     * @param e
     * @param options might include (bool)addAnotherStudent, (Program)goToProgram
     */
    save (e, options = {}) {
      let userDidClick = false
      if (!isEmpty(e)) {
        // stop the propagation of the click
        e.preventDefault()
        userDidClick = true
      } else {
        if (this.saving) {
          return
        }
      }
      const vm = this
      this.$Progress.tempColor('#22c977')
      this.$wait.start('information_card.saving')
      if (!userDidClick) {
        this.$notify({
          group: 'default',
          type: 'success',
          text: 'Auto Saving Start',
          duration: 1000,
          speed: 500
        })
      }
      this.information_card.member_id = this.member_id
      this.information_card.school_year = this.year
      const informationCard = this.information_card.$toJson()
      console.log('InformationCardEdit.save $jason', informationCard)
      informationCard.information_card_contacts = []
      informationCard.information_card_medicals = []
      informationCard.signatures = []

      this.fixStates(informationCard)
      this.fixSchoolGradeTeacher(informationCard)
      if (!this.isValidated) {
        informationCard.signature_data = null
      }
      API.post('/information_cards/save.json', informationCard)
        .then(response => {
          const data = response.data
          if (data.result.isError === false) {
            this.$notify({
              group: 'default',
              type: 'success',
              title: 'information card saved',
              text: data.result.message,
              duration: 2000,
              speed: 1000
            })
            this.information_card.id = data.id
            this.information_card.student_id = data.student_id
            console.log('InformationCardEdit.save pre ', data, this.information_card)
            this.information_card.$save()
            console.log('InformationCardEdit.save post', data, this.information_card)
            InformationCard.insertOrUpdate({ data: data })
              .then(entities => {
                console.log('InformationCardEdit.save', data, this.information_card, entities)
              })
            if (this.isValidated && userDidClick) {
              vm.$wait.start('information_card.saving')
              const localData = {
                information_card_id: data.id,
                student_id: data.student_id
              }
              for (const optionsKey in options) {
                localData[optionsKey] = options[optionsKey]
              }
              this.$emit('complete', localData)
            } else {
              vm.$wait.end('information_card.saving')
              this.$Progress.revertColor()
            }
          } else {
            this.$notify({
              group: 'default',
              type: 'error',
              title: 'information card error',
              text: data.result.message,
              duration: 2000,
              speed: 1000
            })
            this.$Progress.revertColor()
            vm.$wait.end('information_card.saving')
          }
        })
        .catch(e => {
          this.$notify({
            group: 'default',
            type: 'error',
            title: 'information card catch',
            text: e.message,
            duration: 2000,
            speed: 1000
          })
          this.$Progress.revertColor()
          vm.$wait.end('information_card.saving')
        })
    },
    fixSchoolGradeTeacher (card) {
      if (typeof card.school !== 'string' && !isEmpty(card.school)) {
        card.school = card.school.value
      }
      if (typeof card.grade !== 'string' && !isEmpty(card.grade)) {
        card.grade = card.grade.value
      }
      // if (typeof card.teacher != "string" && !isEmpty(card.teacher)) {
      //   if (typeof card.teacher.id !== "undefined") {
      //     card.teacher_id = card.teacher.id;
      //   } else {
      //     card.teacher_id = card.teacher.value;
      //   }
      // }
      return card
    },
    fixStates (card) {
      if (typeof card.state !== 'string' && !isEmpty(card.state)) {
        card.state = card.state.value
      }
      if (
        typeof card.mother_guardian_state !== 'string' &&
        !isEmpty(card.mother_guardian_state)
      ) {
        card.mother_guardian_state = card.mother_guardian_state.value
      }
      if (
        typeof card.father_guardian_state !== 'string' &&
        !isEmpty(card.father_guardian_state)
      ) {
        card.mother_guardian_state = card.father_guardian_state.value
      }
      return card
    },
    change () {
      this.options = {
        penColor: '#0000ff'
      }
    },
    resume () {
      this.options = {
        penColor: '#cc00ff'
      }
    }
  },
  validations: InformationCard.validations,
  watch: {
    minimumToSave () {
      if (!this.saving) {
        // only do no more then once a second
        this.throttleSave()
      }
    },
    shouldAutoSave () {
      if (!this.saving && this.minimumToSave) {
        this.throttleSave()
      }
    },
    countOfMedicals (count) {
      if (count > 0) {
        this.showMedicalsCheckbox = true
      }
    },
    'information_card.address' (address) {
      if (isEmpty(this.information_card.mother_guardian_address)) {
        this.information_card.mother_guardian_address = address
      }
    },
    'information_card.zip' (zip) {
      if (isEmpty(this.information_card.mother_guardian_zip)) {
        this.information_card.mother_guardian_zip = zip
      }
    },
    'information_card.home_phone' (homePhone) {
      if (isEmpty(this.information_card.mother_guardian_phone)) {
        this.information_card.mother_guardian_phone = homePhone
      }
    },
    id (newId, oldId) {
      if (newId === 0 && oldId > 0) {
        if (!isEmpty(this.sourceInformationCard)) {
          console.warn('InformationCardEdit.watch.id', this.sourceInformationCard)
          const vm = this.vm
          this.$nextTick(() => {
            // can use source to start filling new student

          })
        }
      }
    },
    sourceInformationCard (newIC, oldIC) {
      if (!isEmpty(newIC) && this.id === -1) {
        if (!isEmpty(this.sourceInformationCard)) {
          const propertiesToCopy = ['address', 'home_phone', 'city', 'state', 'zip', 'home', 'mother_guardian_first', 'mother_guardian_last', 'mother_guardian_address', 'mother_guardian_city', 'mother_guardian_state', 'mother_guardian_zip', 'mother_guardian_cell', 'mother_guardian_email', 'mother_guardian_is_employee', 'mother_guardian_phone', 'father_guardian_first', 'father_guardian_last', 'father_guardian_address', 'father_guardian_city', 'father_guardian_state', 'father_guardian_zip', 'father_guardian_cell', 'father_guardian_email', 'father_guardian_is_employee', 'father_guardian_phone', 'health_care_provider', 'health_care_provider_phone', 'individual_called', 'insurance_carrier', 'not_to_remove', 'telephone_authorization_code']
          propertiesToCopy.forEach(property => {
            this.information_card[property] = this.sourceInformationCard[property]
          })
        }
      }
    }
  },
  computed: {
    ...mapWaitingGetters({
      saving: 'information_card.saving'
    }),
    ...mapGetters({
      schools: 'schools',
      grades: 'grades',
      states: 'states',
      currentYear: 'year'
    }
    ),

    config: function () {
      return {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'M j, Y',
        altInput: true,
        dateFormat: 'M j, Y',
        minDate: this.startBirthday,
        maxDate: this.endBirthday
      }
    },
    saveButtonText: function () {
      if (this.$wait.is('information_card.saving')) {
        if (this.isValidated) {
          return 'Saving...'
        } else {
          return 'Auto Saving...'
        }
      } else {
        if (this.isValidated) {
          return 'Save'
        } else {
          return 'Not Complete'
        }
      }
    },
    birthdayOutOfRange () {
      const date = this.information_card.date_of_birth
      if (isEmpty(date)) {
        return false
      }
      const result = parseISO(date)
      return !isDateBetween(this.startBirthday, this.endBirthday, result)
    },
    birthday: {
      get: function () {
        const date = this.information_card.date_of_birth
        if (isEmpty(date)) {
          return null
        }
        const result = parseISO(date)
        // date is stored without time so it looks like 7 hours earlier from gmt yesterday
        return dateString(addHours(result, 7), 'MM/dd/yyyy')
      },
      set: function (birthday) {
        if (isEmpty(birthday) || birthday.length < 10) {
          return
        }
        try {
          const result = parse(birthday, 'MM/dd/yyyy', new Date())
          this.information_card.date_of_birth = result.toISOString()
        } catch (e) {
          console.warn('InformationCard.birthday', birthday, e)
        }
      }
    },
    birthdayAsDate () {
      const birthday = this.information_card.date_of_birth
      const date = parseISO(birthday)
      return date
    },
    years: function () {
      let year = new Date().getFullYear()
      if (this.currentYear > 0) {
        year = this.currentYear
      }
      let years = range(year, year + 2)
      years = years.map(function (year) {
        return {
          key: year,
          value: year.toString() + '-' + (year + 1).toString()
        }
      })
      return years
    },
    selectedProgram: {
      get: function () {
        return this.programObject(this.programSlug)
      },
      set: function (selectedProgram) {
        this.$emit('program-selected', selectedProgram.slug)
      }
    },
    selectedYear: {
      get: function () {
        return {
          key: this.year,
          value: this.year.toString() + '-' + (this.year + 1).toString()
        }
      },
      set: function (year) {
        this.$emit('year-selected', year.key)
      }
    },
    grade: {
      // getter
      get: function () {
        const key = this.information_card.grade
        let gradeObject = null
        if (typeof key !== 'undefined' && key !== null) {
          this.grades.every(function (level) {
            gradeObject = level.gradeGroup.find(function (grade) {
              return key.toString() === grade.value.toString()
            })
            return typeof gradeObject === 'undefined'
          })
        }
        return gradeObject
      },
      // setter
      set: function (newValue) {
        if (isEmpty(newValue)) {
          this.information_card.grade = null
        } else {
          const grade = newValue.value
          this.information_card.grade = grade
        }
      }
    },
    school: {
      // getter
      get: function () {
        const key = this.information_card.school
        let schoolObject = null
        if (typeof key !== 'undefined' && key !== null) {
          this.schools.every(function (level) {
            schoolObject = level.schoolGroup.find(function (school) {
              return key === school.value
            })
            return typeof schoolObject === 'undefined'
          })
        }
        return schoolObject
      },
      // setter
      set: function (newValue) {
        if (isEmpty(newValue)) {
          this.information_card.school = null
        } else {
          this.information_card.school = newValue.value
        }
      }
    },
    teacher: {
      // getter
      get: function () {
        const teacherId = this.information_card.teacher_id
        let teacherObject = null
        if (typeof teacherId !== 'undefined' && teacherId !== null) {
          teacherObject = this.allTeachers.find(teacher => {
            return teacher.id === teacherId
          })
        }
        return teacherObject
      },
      // setter
      set: function (newValue) {
        if (isEmpty(newValue)) {
          this.information_card.teacher_id = null
        } else {
          const newId = newValue.id
          this.information_card.teacher_id = newId
        }
      }
    },
    lives_with: {
      // getter
      get: function () {
        const key = this.information_card.home
        let livesWith = null
        if (typeof key !== 'undefined' && key !== null) {
          livesWith = this.lives_with_options.find(function (
            livesWithOptions
          ) {
            return key === livesWithOptions.name
          })
        }
        return livesWith
      },
      // setter
      set: function (newValue) {
        this.information_card.home = newValue.name
      }
    },
    state: {
      // getter
      get: function () {
        return this.stateObject(this.information_card.state)
      },
      // setter
      set: function (newValue) {
        this.information_card.state = newValue.key
      }
    },
    guardianState: {
      // getter
      get: function () {
        return this.stateObject(this.information_card.mother_guardian_state)
      },
      // setter
      set: function (newValue) {
        this.information_card.mother_guardian_state = newValue.key
      }
    },
    secondGuardianState: {
      // getter
      get: function () {
        return this.stateObject(this.information_card.father_guardian_state)
      },
      // setter
      set: function (newValue) {
        this.information_card.father_guardian_state = newValue.key
      }
    },
    contacts () {
      return InformationCardContact.query()
        .where('information_card_id', this.information_card_id)
        .get()
    },
    programs () {
      return Program.query()
        .where('active', true)
        .orderBy('name')
        .get()
    },
    countOfMedicals () {
      if (!isEmpty(this.information_card.information_card_medicals)) {
        const count = this.information_card.information_card_medicals.length
        return count
      }
      return 0
    },
    hasId () {
      return (
        !isEmpty(this.information_card_id) || !isNull(this.information_card_id)
      )
    },
    hasProgram () {
      return !isEmpty(this.program) || !isNull(this.program)
    },
    information_card_id () {
      if (this.id < 0) {
        return this.information_card.id
      } else {
        return this.id
      }
    },
    member () {
      return Member.find(this.member_id)
    },
    authMember () {
      if (this.auth_id !== null) {
        return Member.find(this.auth_id)
      } else {
        return this.member
      }
    },
    totalToValidate () {
      const manualCheckCount = 1 // contacts count
      const keys = Object.keys(this.$v.information_card.$params)
      return keys.length + manualCheckCount
    },
    isValidated () {
      return this.countOfValidated === this.totalToValidate
    },
    countOfValidated () {
      let count = 0
      for (const key in this.$v.information_card.$params) {
        const value = this.$v.information_card[key]
        if (!value.$invalid) {
          count = count + 1
        }
      }
      if (this.contactsComplete) {
        count = count + 1
      }
      return count
    },
    validated () {
      const validated = []
      for (const key in this.$v.information_card.$params) {
        const value = this.$v.information_card[key]
        if (!value.$invalid) {
          validated.push(key)
        }
      }
      if (this.contactsComplete) {
        validated.push('contacts')
      }
      return validated
    },
    invalid () {
      const invalid = []
      for (const key in this.$v.information_card.$params) {
        const value = this.$v.information_card[key]
        if (value.$invalid) {
          invalid.push(key)
        }
      }
      if (!this.contactsComplete) {
        invalid.push('contacts')
      }
      return invalid
    },
    countToValidate () {
      this.$Progress.set((this.countOfValidated / this.totalToValidate) * 100)
      return this.totalToValidate - this.countOfValidated
    },
    readyToSign () {
      return this.countToValidate <= 1
    },
    /**
     * @return {boolean}
     */
    contactsComplete () {
      if (this.contacts.length >= this.contactCountMinimum) {
        return true
      }
      return false
    },
    /**
     * @return {boolean}
     */
    studentInfoValidated () {
      const validator = this.$v.information_card
      return (
        !validator.student_first.$invalid &&
        !validator.student_last.$invalid &&
        !validator.grade.$invalid &&
        !validator.school.$invalid &&
        !validator.address.$invalid &&
        !validator.city.$invalid &&
        !validator.zip.$invalid &&
        !validator.gender.$invalid &&
        // !this.$v.birthday.$invalid &&
        !validator.enrollment_type.$invalid &&
        !validator.home_phone.$invalid
      )
    },
    /**
     * @return {boolean}
     */
    guardiansValidated () {
      const validator = this.$v.information_card
      return (
        !validator.home.$invalid &&
        !validator.mother_guardian_first.$invalid &&
        !validator.mother_guardian_last.$invalid &&
        !validator.mother_guardian_address.$invalid &&
        !validator.mother_guardian_phone.$invalid &&
        !validator.mother_guardian_cell.$invalid &&
        !validator.city.$invalid &&
        !validator.state.$invalid &&
        !validator.zip.$invalid
      )
    },
    /**
     * @return {boolean}
     */
    healthValidated () {
      const validator = this.$v.information_card
      return (
        !validator.insurance_carrier.$invalid &&
        !validator.health_care_provider.$invalid &&
        !validator.health_care_provider_phone.$invalid
      )
    },
    additionalInfoValidated () {
      const validator = this.$v.information_card
      return (
        !validator.has_iep.$invalid &&
        !validator.has_504.$invalid &&
        !validator.individual_called.$invalid &&
        !validator.custody_papers_filed.$invalid
      )
    },
    minimumToSave () {
      if (
        isEmpty(this.information_card) ||
        !isEmpty(this.information_card.id) ||
        this.id !== -1
      ) {
        return false
      } else {
        return this.studentInfoValidated
      }
    },
    shouldAutoSave () {
      if (this.minimumToSave && !this.isValidated) {
        const countToValidate = this.countToValidate
        if (countToValidate % 7 === 0) {
          return true
        }
      }
      return false
    },
    maxDate () {
      /**
       * moment uses zero based month
       * @type {*|number}
       */
      const cutoffMonth = this.hasProgram ? this.program.cutoff_month - 1 : 8
      const cutoffDay = this.hasProgram ? this.program.cutoff_day : 1
      const minAgeInMonths = (this.hasProgram ? this.program.min_age : 2.5) * 12
      const maxDate = new Date(this.information_card.school_year, cutoffMonth, cutoffDay)
      // const maxDate = moment().year(this.information_card.school_year).month(cutoffMonth).date(cutoffDay).subtract(minAge, 'years')
      return subMonths(maxDate, minAgeInMonths)
    },
    minDate () {
      /**
       * moment uses zero based months
       * @type {*|number}
       */
      const cutoffMonth = this.hasProgram ? this.program.cutoff_month - 1 : 8
      const cutoffDay = this.hasProgram ? this.program.cutoff_day : 1
      const maxAgeInMonths = (this.hasProgram ? this.program.max_age : 120) * 12
      const maxDate = new Date(this.information_card.school_year, cutoffMonth, cutoffDay)
      // const maxDate = moment().year(this.information_card.school_year).month(cutoffMonth).date(cutoffDay).subtract(maxAge, 'years')
      return subMonths(maxDate, maxAgeInMonths)
    },
    cutoffDate () {
      let schoolYear = this.year
      console.log('schoolYear', schoolYear)
      console.log('selectedProgram', this.program)
      if (
        !isEmpty(this.information_card) &&
        !isEmpty(this.information_card.school_year)
      ) {
        console.log('school_year ic', this.information_card.school_year)
        schoolYear = this.information_card.school_year
      }
      console.log('schoolYear2', schoolYear)

      const cutoffMonth = !isEmpty(this.program) ? this.program.cutoff_month - 1 : 8
      const cutoffDay = !isEmpty(this.program) ? this.program.cutoff_day : 1
      return new Date(schoolYear, cutoffMonth, cutoffDay)
    },
    minAge () {
      return !isEmpty(this.program) ? this.program.min_age : 2.5
    },
    maxAge () {
      return !isEmpty(this.program) ? this.program.max_age : 100
    },
    startBirthday () {
      return this.minDate
    },
    endBirthday () {
      return this.maxDate
    },
    minBirthday () {
      return this.minDate.format('YYYY-MM-DD')
    },
    maxBirthday () {
      return this.maxDate.format('YYYY-MM-DD')
    },
    teachers () {
      let t = Teacher.query()
        .orderBy('last', 'asc')
        .orderBy('first', 'asc')
      if (typeof this.school !== 'undefined' && this.school !== null) {
        const school = this.school.value
        t = t.where('school', school)
      }
      t = t.get()
      return t
    },
    allTeachers () {
      return Teacher.all()
    }
  }
}
</script>

<style scoped></style>
